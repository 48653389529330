.admin_main {
    align-items: center;
    background-image: url("../../assets/background.jpeg");
    background-repeat: no-repeat;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
}

.admin_body {
    /* border: 2px solid white; */
    max-width: 1400px;
    width: 100%;
    margin: auto;
}

.linkTag {
    display: block;
    /* border: 1px solid black; */
    margin-top: 20px;
    padding: 12px;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    /* background-color: #db93fff2; */
    background-color: rgba(255, 255, 255, 0.2);
}
.mainFlexBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
}

.MainlinkContainer {
    /* border: 1px solid red; */
    width: 100%;
    padding: 2rem 2.25rem;
    box-sizing: border-box;
    /* background-color: #8f66dd; */
    border-radius: 10px;
    background-image: linear-gradient( 45deg, rgb(101 55 155 / 80%), rgb(136 106 234 / 80%) );
}
.linkConImgHolder {
    display: flex;
    align-items: center;
    justify-content: center;
}
.linkConImgHolder > .logoHolder {
    width: 50%;
    width: 150px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
}
.linkConImgHolder > .nameHolder {
    height: 100%;
    /* border: 1px solid green; */
    width: 50%;

    display: flex;
    align-items: center;
}

.games_img {
    padding-top: 50px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mainCardsContianer {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    padding:10px;
}
.cardContainer {
    display: flex !important;
    flex-direction: column;
    padding: 2rem 2.25rem;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    display: flex !important;
    /* border: 1px solid rgb(225, 230, 223); */
    max-width: 340px;
    width: 100%;
    height: 152px;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
}
.updateBox {
    border-radius: 30px;
    background-color: rgba(19, 0, 97, 0.3);
}
.cardMangeAdmin {
    background-color: rgb(114, 95, 226);
}
.foreCastManggementCard {
    background-color: rgb(110, 172, 102);
}
.cardImg {
    width: 100px;
}

.imageLogoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.imageLogoContainer > img {
    width: 204px;
    height: 150px;
}

.imageLogoContainer > p {
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
}

.textLogoContainer {
    /* border: 1px solid yellow; */
    display: flex;
    /* flex-direction: row; */
    justify-content: space-evenly;
    color: white;
    padding: 20px;
}
.adminPanelName {
    padding-left: 20px;
    font-size: 1.15rem;
    font-weight: 500;
}
.fullNameDiv {
    padding-top: 20px;
    font-size: 1.15rem;
    font-weight: 500;
}
