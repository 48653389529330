.background {
  height: 100vh;
  align-items: center;
  background-image: url('../../assets/Background-sport.png');
  background-size: cover;
  object-fit: cover;
  position: relative;
}

.empty {
  display: flex;
  height: 100%;
}

.conta_left {
  width: 50%;
}

.login {
  display: flex;
  justify-content: center;
  width: 50%;
  align-items: center;
}

.login_header_img {
  height: 200px;
  width: 300px;
  object-fit: cover;
  margin-left: 51px;
}

.main_page {
  display: flex;
  justify-content: center;
  width: 45%;
  align-items: center;
}

.input {
  background: #fff !important;
  border-radius: 50px;
  padding: 15px 28px;
  color: rgb(33 112 182) !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15) !important;
  font-size: 18px;
  margin-bottom: 40px;
  font-weight: 600;
  font-family: 'Poppins';
  max-width: 375px;
  width: 100%;

  &:focus {
    border: 0.1rem solid #997af0;
    outline: none;
  }
}

.input::placeholder {
  color: rgb(33 112 182) !important;
  font-size: 18px;
  font-weight: 600;
}

.ham_login{
  margin-left: 75px;
}

.nav_link {
    color: white;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    padding: 20px 40px;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    width: 200px;
    height: 45px;
    overflow: hidden;
    border-radius: 40px;
    scale: 0.75;
    text-align: center;
}

.nav_link:focus,
.nav_link:hover {
    color: white;
}

.nav_link span {
  position: relative;
  color: #fff;
  font-size: 25px;
  font-family: Arial;
  letter-spacing: 8px;
  z-index: 1;
  top: 11px;
  font-weight: 600;
}

.nav_link .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 288px;
  height: 200px;
  background: #4973ff;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

.nav_link .liquid::after,
.nav_link .liquid::before {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  background: #000;
}

.nav_link .liquid::before {
  
  border-radius: 45%;
  background: rgba(20, 20, 20, 1);
  animation: animate-float 5s linear infinite;
}

.nav_link .liquid::after {
  
  border-radius: 40%;
  background: rgba(20, 20, 20, .5);
  animation: animate-float 10s linear infinite;
}

.nav_link:hover .liquid{
  top: -120px;
}
