.subnavbar {
position:sticky;
display: flex;
justify-content: space-between;
}
.navbarlogo {
    height: 22vh;
    margin-left: 40%;

}
.rightSubNavbar {
    padding: 1%;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    margin-right: 10%;
}

.rightButtons {
    margin-left: 30%;
}
.rightTwoButton {
color: black;
cursor: pointer;
font-weight: bold;
width: 120%;
height: 5vh;
border-radius: 5px;
border-style: none;
}

.rightTwoButton:hover {
    color: white;
    background-color: #c37bba;
}