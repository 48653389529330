

.heading {
  text-align: center;
  color: rgb(69,58,99);
  margin: 10px 0 20px;
  font-size: 26px;
}
.subHeading{
  font-size: 18px;
  color: rgb(69,58,99);
  margin: 10px 0;
}
.mainContainer{
  background-image: url("../../../assets/background.jpeg");
  width: 100%;
  height: 50vh;
}

.FOPform {
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 20px;
  width: calc(100% - 40px);
  margin: auto;
  box-shadow: -4px 10px 16px rgba(0,0,0,0.1);
  box-sizing: border-box;
}
.formFieldsWrap{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 20px;
  margin-bottom: 30px;
}

.h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.label {
  display: block;
  margin-bottom: 5px;
  color: rgb(69,58,99);
}

.select, .input, .textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  padding: 10px 30px;
  background-color: rgb(137, 119, 186);
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  color: white;
  background-color: rgb(69,58,99);
}

.fopVenueChilds{
  width: 100%;
  max-width: calc(33.3% - 15px);
}
@media (max-width: 790px) {
  .fopVenueChilds{
    max-width: calc(50% - 10px);
  }
}
@media (max-width: 590px) {
  .fopVenueChilds{
    max-width: calc(100%);
  }
}
.fopVenueChilds label{
  font-size: 14px;
  color: #333333;
}

.fopVenueChilds input {
  width: 100%;
 border-radius: 10px;
 color: rgb(69,58,99);
outline: none;
 border: 1px solid #ccc;
 box-sizing: border-box;
 margin: 0;
}

.fopVenueChilds select {
  border-radius: 10px;
  margin: 0;
  width: 100%;
}

.fixedButtons {
  border-style: none;
  padding: 0.5%;
  width: 7%;
  border-radius: 10px;
  color: black;
  background-color: rgb(233, 212, 230);
  font-weight: bold;
  cursor: pointer;
  border: 1px solid rgb(89, 81, 81);
}
.fixedButtons:hover {
  color: white;
  background-color: rgb(51, 121, 161);
}

.currentPermissions{
  width: 100%;
}
.UserModulePermission{
  padding: 6px 10px;
  background-color: rgba(137, 119, 186, 0.1);
  border: 1px solid rgb(137, 119, 186);
  border-radius: 6px;
  color: rgb(69,58,99);
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
}
.locationsList{
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.UserModulePermission div{
  color: red;
  cursor: pointer;
}

.addmenu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.menuButtons{
  display: flex;
  align-items: center;
  gap: 40px;
}

.menuButtons button {
  padding: 10px;
    margin: 5px;
    background-color: #b9a7e3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btnaddmenu{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btnaddmenu button{
  padding: 10px;
  width: 40%;
  margin: 5px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.listitem{
  padding: 10px;
  margin: 5px;
  background-color: #ddd;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;
  margin-top: 0px;
}

.selectedmenu{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.selectedKeys{
  padding: 10px;
  margin: 5px;
  background-color: #808088;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;
  margin-top: 0px;
}

.listitem span {
  width: 25px;
  height: 25px;
  background-color: #fff;
  color: #000;
}

.displayHotelBoc {
  width: 40%;
  background-color: #fff;
  border: 1px solid #debbd3;
  padding: 30px;
  margin-left: 350px;
  cursor: pointer;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: auto;
  margin-top: 5%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.displayHotelBoc > div {
  padding: 10px;
  border: 1px solid rgb(206, 187, 187);
  
}

.displayHotelBoc > p {
  text-align: center;
  vertical-align: middle;
  margin-left: 2%;
  padding: 1px;
  border: 2px solid rgb(232, 219, 224);
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  font-weight: 600;
}

.closeButton{
  
}

.close-button:hover {
  color: #481515; 
}

.menuCrossBtn {
  background-color: #8079dd;
  padding: 8px 10px;
}
.menuCrossBtn:hover {
background-color: #4b45ab;

}

.addAccomodationPerm {
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
  background-color: #d37fcb;
}
.permDiv, .addVenueName {
  margin-top: 4%;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #8cd3db;
}




