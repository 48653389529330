.moduleUserContainer {
  background-image: url("../../assets/background.jpeg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  overflow: auto;
  position: fixed;
}

.body {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  max-width: 480px;
  width: calc(100% - 30px);
  font-size: 13px;
  font-weight: 400;
  padding: 40px 30px;
  box-sizing: border-box;
  border: 1px solid #eff2f5;
  border-radius: 0.475rem;
  box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
  margin: 40px auto 20px;
}

.body select{
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  padding: 12px 10px;
}

.contentform {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.header {
  font-size: calc(1.26rem + 0.12vw);
  font-weight: 600;
  margin: 0 0 10px;
  text-align: center;
}
.menus {
  display: flex;
  align-items: center;
}

.selectOptions > div{
  padding: 8px 10px;
}
.selectOptions > fieldset{
  border-radius: 6px;
}
.MuiSelect-select {
  border-radius: 5px;
  background-color: #f5f8fa;
  border: 2px solid #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.lunchMenuWrap{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 15px;
}
.lunchMenuWrap .lunchMenu{
  padding: 5px 8px;
  border-radius: 6px;
  border: 1px solid #3a1c7a55;
  background-color: #FFF;
}

.UserDetailsContainer {
  padding: 4%;
}

.NavbarConatiner {
  position: sticky;
  background-color: #8f66dd;
  padding: 0.7%;
}

.itemNameButtons {
  padding: 10px;
  margin: 5px;
  background-color: #b9a7e3;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.itemNameButtons:hover {
  color: white;
  background-color: #3a1c7a;
}

.lunchTypeContainer {
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #c6a5bd;
  border: 1px solid gray;
}

.lunchTypeContainer h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.lunchTypeContainer ul {
  list-style-type: none;
  padding: 0;
}

.lunchTypeContainer li {
  margin-bottom: 5px;
}

.dateOption {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f8fa;
  border: 2px solid #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  height: 30px;
}

.venueAndHotel {
  margin-left: 5%;
}

.changeUT {
  margin-top: 5%;
  padding: 1%;
}

.changeUT option {
  padding: 2%;
}

.AddButtonMenu {
  width: 10%;
  padding: 3px;
  border-style: none;
  border: 1px solid grey;
  background-color: rgb(87, 137, 171);
  cursor: pointer;
  margin-top: 1%;
}
.lunchItemsMenu {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  color: #3b2f57;
  & button{
    background-color: transparent;
    width: auto;
    border: none;
    outline: none;
    width: 20px;
    height: 20px;
    background-color: #3a1c7a55;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
  }
}

.UserModuleSubmitBtn {
  padding: 10px;
  margin-top: 10px;
  background-color: #8f66dd;
  color: #FFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.headingModUser {
  text-align: center;
  background-color: #3b2f57;
  color: white;
  padding: 0.5%;
}

.active_breakFast{
  background-color: rgb(61, 61, 255);
  color: white;
} 
.date_input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}