.mainDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-bottom: 20px;
}
.buttonNav {
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
    margin: 0.5rem;
    font-weight: 600;
    color: #7e8299;
    border-color: #f5f8fa;
    background-color: #f5f8fa;
    border-radius: 0.475rem;
    transition: all 0.4s;
    font-size: .925rem;
    border: none;
}
.buttonNav button {
    border: none;
}
.buttonNav:hover {
    background-color: #009ef7;
    color: #fff;
    border-color: #009ef7;
}

.welcomeDiv {
    font-weight: bolder;
    font-size: larger;
    padding: 2px;
}
.dayDiv {
    font-weight: bold;
    font-size: large;
    padding: 2px;
}

.fullNameDiv {
    padding: 10px;
}
