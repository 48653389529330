

table {
    width: 95%;
 
    border: 1px solid #ddd;
    margin: 20px;

}
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    padding: 1%;
    background-color: #f2f2f2;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:nth-child(odd) {
    background-color: whitesmoke;
  }
  
  button {
    background-color: #83bae1;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color:black;
    border-style: none;
     border-radius: 10px;
     text-decoration: none;
     transition-duration: 0.4s;
     font-weight: bold;
     letter-spacing: 1px;
  }

  button:hover {
    background-color: #36a5f4;
    color: white;
  }

  .DltBtn {
    background-color: rgb(138, 215, 215);
    color:black;
   border-style: none;
    border-radius: 10px;
    padding: 8px 16px;
    text-decoration: none;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .DltBtn:hover {
    background-color: #36a5f4;
    color: white;
    
  }
  


