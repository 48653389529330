.subAdmin {
  background-image: url("../../../assets/background.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vh;
}
.subAdminContainer {
  color: white;
  margin-left: 5%;
}

.subAdminDetails {
  background-color: white;
  border-radius: 8px;
  margin: 20px;
  padding: 20px;
  box-shadow: -4px 6px 16px rgba(0, 0, 0, 0.1);
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.subAdminDetails th,
.subAdminDetails td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.subAdminActions {
  display: flex;
  gap: 10px;
}
.EditsubAdmin {
  width: 100%;
  cursor: pointer;
  border: 1px solid rgb(0, 158, 247);
  color: black;
  border-radius: 6px;
}

.changePassword:hover {
  color: white;
  background-color: rgb(114, 92, 236);
}
.EditsubAdmin:hover {
  color: white;
  background-color: rgb(0, 158, 247);
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modalcontent {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.updateFOPdivs {
  margin-bottom: 20px;
}
.updateFOPdivs input {
  width: 80%;
  padding: 1%;
}

.updateFOPdivs label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.updateFOPdivs input[type="number"] {
  width: 82%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

.updateFOPdivs input[type="number"]:focus {
  border-color: #007bff;
  outline: none;
}
.updateButton {
  padding: 5px 40px;
  background-color: #584c72;
  color: #fff;
  border: none;
  font-weight: bold;
  letter-spacing: 2px;
  margin-left: 1%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.updateButton:hover {
  background-color: #8778a8;
  color: black;
}

.foreCastingDate {
  display: flex;
  align-items: center;
  gap:10px;
}

.dateInputforecasting {
  width: 150px;
  margin-top: 25px;
  margin-left: 30px;
  padding: 12px;
  cursor: pointer;
}
.filterDate {
  margin-top: 30px;
}
.hotelFilter,
.mealLocationFilter {
  padding: 13px;
  cursor: pointer;
  margin-top: 25px;
  margin-left: 10px;
}

.tableHeader {
  background: #8e66dc;
  align-items: center;
  color: #fff;
  display: grid;
  grid-template-columns: 200px 200px 200px 1fr 1fr 100px;
  padding: 15px 20px;
  min-width: fit-content;
}

.bodyContainer {
  max-height: 700px;
  overflow: auto;
}

.divContainer {
  display: grid;
  grid-template-columns: 200px 200px 200px 1fr 1fr 100px;
  align-items: center;
  min-width: fit-content;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  padding: 20px;
}

.subAdminDivContainer {
  display: grid;
  grid-template-columns: 50px 150px 220px 150px 100px 1fr 170px;
  align-items: center;
  min-width: fit-content;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  padding: 20px;
}

.subAdminTableHeader {
  background: #8e66dc;
  align-items: center;
  color: #fff;
  display: grid;
  grid-template-columns: 50px 150px 220px 150px 100px 1fr 170px;
  padding: 15px 20px;
  min-width: fit-content;
}


.fopDivContainer {
  display: grid;
  grid-template-columns: 60px 115px 250px 100px 150px 100px 150px 120px 150px 100px;
  align-items: center;
  min-width: fit-content;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  padding: 20px;
}

.fopTableHeader {
  background: #8e66dc;
  align-items: center;
  color: #fff;
  display: grid;
  grid-template-columns: 60px 115px 250px 100px 150px 100px 150px 120px 150px 100px;
  padding: 15px 20px;
  min-width: fit-content;
}

.accomadationDivContainer {
  display: grid;
  grid-template-columns: 60px 100px 60px 100px 100px 100px 60px 150px 150px 150px 150px 150px 100px;
  align-items: center;
  min-width: fit-content;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  padding: 20px;
}

.accomadationTableHeader {
  background: #8e66dc;
  align-items: center;
  color: #fff;
  display: grid;
  grid-template-columns: 60px 100px 60px 100px 100px 100px 60px 150px 150px 150px 150px 150px 100px;
  padding: 15px 20px;
  min-width: fit-content;
}


.container2 {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.table2 {
  width: 95vw;
  /* overflow: auto; */
}

