.NavbarConatiner {
    position: sticky;
    background-color: #8f66dd;
    padding: 0.7%;
}

.manageContainer {
    background-image: url("../../assets/background.jpeg");
    width: 100%;
    height: 140vh;
    background-repeat: no-repeat;
    overflow: auto;
  
}
.manageButton{

    margin-top: 35%;
}
.manageMenu {
   color: white;
}
.mainSelectTag {
    background-color: white;
    margin-top: 13%;
    
}

.topContainer {
    display: flex;
    justify-content: space-around;
}




.container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
}

.manageButton {
    align-items: center;
}

.mainSelectTag {
    width: 200px;
}

.MenuData {
    width: 100%;
    box-sizing: border-box;
}

h2 {
    margin-bottom: 10px;
}

.selectTag {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    border: 1px solid red;
}
.selectVenue {
    color: white;
    margin-top: 5%;
    font-weight: bold;
    font-size: larger;
  
}

.dateInput {
    width: 169px;
    margin-top: 25px;
    padding: 18px;
    cursor: pointer;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
}

.menuTypeFilter {
    margin-top: 25px;
    padding: 19px;
}